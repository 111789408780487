<template>
  <!--
    An error page to land when getting a 503 error
  -->
  <div class="error503">
    <Portlet
      :title="$t('errorComponent.backendIsCurrentlyUnavailable')"
      sub-title="503"
      icon="info-circle"
    >
      <LoadingPlaceholder v-if="loading" />

      <template v-else>
        <p class="mb-5">
          {{ $t('errorComponent.youWillBeForwardedSoon') }}
        </p>
        <strong>{{ $t('errorComponent.nextCheckIn') }}</strong> 
        <div class="progress">
          <div
            :aria-valuenow="secondsToGo * 10"
            :style="`width:${ 100 - ( secondsToGo * 10 ) }%;`"
            class="progress-bar"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {{ secondsToGo }} s
          </div>
        </div>
      </template>
    </Portlet>
  </div>
</template>

<script>
export default {
  name: "Error503",
  props: {
    fromRoute: {
      type: Object,
      required: false,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      interval: null,
      countdownInterval: null,
      loading: false,
      secondsToGo: 10,
      cancelSource: null
    }
  },
  created () {
    //this.countDownSeconds(10);
    this.testRequest();
  },
  beforeDestroy () {
    window.clearInterval(this.interval);
    window.clearInterval(this.countdownInterval);
    this.interval = null;
    this.countdownInterval = null;
    this.cancelRequest();
  },
  methods: {
    handleBack () {
      if (this.fromRoute == null) {
        this.$router.push('/');
        return;
      }
      if (this.fromRoute.path == null) {
        this.$router.push('/');
      } else {
        this.$router.push(this.fromRoute);
      }
    },
    countDownSeconds () {
      this.countdownInterval = window.setInterval(() => {
        this.secondsToGo--;
        if (this.secondsToGo <= 0) {
          window.clearInterval(this.countdownInterval);
          this.countdownInterval = null;
          this.secondsToGo = 10;
          this.testRequest();
        }
      }, 1000);
    },
    testRequest () {
      this.loading = true;
      this.cancelRequest();
      this.cancelSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelSource.token };
      this.axios.get(`/Setting/GetAll`, requestConfig)
        .then((response) => {
                    
          if (response) {
            this.cancelSource = null;
          }
          window.clearInterval(this.interval);
          this.handleBack();
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
          this.countDownSeconds(10);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
    cancelRequest () {
      if (this.cancelSource) {
        this.cancelSource.cancel();
      }
    }
  }
}
</script>

<style scoped>
.progress {
  width: 300px;
  height: 30px;
  font-size: 1rem;
}
</style>
